<template>
    <div class="main">
        <div class="title">{{ title }}</div>
        <div class="top">
            <el-form
                :model="formData"
                :rules="rules"
                ref="form"
                label-width="156px"
                label-position="right"
                size="small"
                style="max-width: 700px"
            >
                <el-form-item prop="name" :label="tpType === 'TECHNOLOGY' ? '成果名称' : '产品名称'">
                    <el-input
                        style="width: 215px"
                        :placeholder="tpType === 'TECHNOLOGY' ? '请输入成果名称' : '请输入产品名称'"
                        v-model="formData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="field" label="所属领域">
                    <el-select
                        v-model="formData.field"
                        clearable
                        filterable
                        placeholder="请选择所属领域"
                        style="width: 215px"
                    >
                        <el-option
                            v-for="item in fieldOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="owner" :label="tpType == 'TECHNOLOGY' ? '成果所属人' : '产品持有人'">
                    <el-select
                        style="width: 400px"
                        class="suffix"
                        v-model="formData.owner"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        :placeholder="`请输入${tpType === 'TECHNOLOGY' ? '成果所属人' : '产品持有人'}，按回车分割`"
                        clearable
                        popper-class="hideSelect"
                    >
                    </el-select>
                </el-form-item>
                <el-form-item prop="stage" label="所处阶段">
                    <el-radio-group v-model="formData.stage">
                        <el-radio v-for="item in stageOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="digest" :label="`${tpType === 'TECHNOLOGY' ? '摘要' : '产品概述'}`" class="block">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        v-model="formData.digest"
                        maxlength="300"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 6 }"
                        :placeholder="`${
                            tpType === 'TECHNOLOGY' ? '请填写摘要描述，300字以内' : '请填写产品概述，300字以内'
                        }`"
                        resize="none"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="manual"
                    :label="`${tpType === 'TECHNOLOGY' ? '成果' : '产品'}说明`"
                    style="width: 800px"
                >
                    <rich-text v-model="formData.manual"></rich-text>
                </el-form-item>
                <el-form-item prop="patented" :label="`是否专利${tpType === 'TECHNOLOGY' ? '技术' : '产品'}`">
                    <el-radio-group v-model="formData.patented">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="patentNo" label="包含专利号" v-if="formData.patented">
                    <el-select
                        style="width: 400px"
                        v-model="formData.patentNo"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入专利号，按回车分割"
                        clearable
                        popper-class="hideSelect"
                    >
                        <div slot="empty"></div>
                    </el-select>
                </el-form-item>
                <el-form-item prop="img" :label="`${tpType === 'TECHNOLOGY' ? '技术' : '产品'}附图`">
                    <multi-upload v-model="formData.img" :limit="6">
                        {{
                            `${
                                tpType === 'TECHNOLOGY'
                                    ? '请上传技术附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                                    : '请上传产品附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                            }`
                        }}
                    </multi-upload>
                </el-form-item>

                <el-form-item prop="tradingMethod" v-if="tpType === 'PRODUCT'" label="交易方式">
                    <el-radio-group v-model="formData.tradingMethod">
                        <el-radio v-for="item in tradingMethodOptionsDemands" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="tradingMethod" v-else label="交易方式">
                    <el-radio-group v-model="formData.tradingMethod">
                        <el-radio v-for="item in tradingMethodOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :disabled="formData.negotiateDirectly"
                        @change="Price"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px; width: 215px"
                    >
                    </el-input-number>
                    <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        style="width: 215px"
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        placeholder="请输入"
                        :controls="false"
                    >
                    </el-input-number>
                </el-form-item>
                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value"
                            >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    prop="commission"
                    :label="formData.commissionType == 'COMMISSION' ? '佣金（万元）' : '佣金（%）'"
                >
                    <el-input placeholder="请输入佣金" style="width: 215px" v-model="formData.commission"></el-input>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input style="width: 215px" v-model="formData.contact"></el-input>
                </el-form-item>
                <el-form-item prop="phone" label="电话">
                    <el-input style="width: 215px" v-model="formData.phone"></el-input>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input style="width: 215px" v-model="formData.email"></el-input>
                </el-form-item>
                <el-form-item prop="address" label="所在地区">
                    <el-input placeholder="请输入所在地区" v-model="formData.address"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="onSave">确定</el-button>
                <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
                <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { phonePattern, tradingMethodOptionsDemands } from '../../../utils/variables';
export default {
    name: 'TechnologyProductEdit',
    data() {
        return {
            needChange: true,
            saving: false,
            formData: {
                patentNo: [],
                isPatented: true,
                owner: []
            },
            fieldOptions: [],
            stageOptions: [
                { label: '研发阶段', value: 'RESEARCH_AND_DEVELOPMENT' },
                { label: '样品阶段', value: 'SAMPLE' },
                { label: '小试阶段', value: 'SMALL_TEST' },
                { label: '中试阶段', value: 'PILOT_TEST' },
                { label: '量产阶段', value: 'MASS_PRODUCTION' }
            ],
            tradingMethodOptions: [
                { label: '技术转让', value: 'TRANSFER' },
                { label: '技术许可', value: 'PERMIT' },
                { label: '技术入股', value: 'INVEST' },
                { label: '合作生产', value: 'CO_PRODUCTION' },
                { label: '其他', value: 'OTHER' }
            ],
            tradingMethodOptionsDemands,
            commissionTypeOptions: [
                { label: '佣金', value: 'COMMISSION' },
                { label: '百分比', value: 'PERCENTAGE' }
            ],
            tpTypeOptions: [
                { label: '技术', value: 'TECHNOLOGY' },
                { label: '产品', value: 'PRODUCT' }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo']),
        tpType() {
            return this.$route.query.tpType;
        },
        title() {
            const list = ['发布供给信息-技术', '发布供给信息-产品'];
            return list[this.tpType == 'TECHNOLOGY' ? 0 : 1];
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: `请输入${this.tpType === 'TECHNOLOGY' ? '成果' : '产品'}名称`,
                        trigger: 'blur'
                    }
                ],
                // field: [{ required: true, message: '请选择所属领域', trigger: 'blur' }],
                // owner: [
                //     {
                //         required: true,
                //         message: `请输入${this.tpType === 'TECHNOLOGY' ? '成果所属人' : '产品持有人'}`,
                //         trigger: 'blur'
                //     }
                // ],
                // stage: [{ required: true, message: '请选择所处阶段', trigger: 'blur' }],
                // img: [
                //     {
                //         required: true,
                //         message: `请上传${this.tpType === 'TECHNOLOGY' ? '技术' : '产品'}附图`,
                //         trigger: 'blur'
                //     }
                // ],
                // digest: [
                //     {
                //         required: true,
                //         message: `请填写${this.tpType === 'TECHNOLOGY' ? '摘要' : '产品概述'}`,
                //         trigger: 'blur'
                //     }
                // ],
                // manual: [
                //     {
                //         required: true,
                //         message: `请输入${this.tpType === 'TECHNOLOGY' ? '成果' : '产品'}说明`,
                //         trigger: 'blur'
                //     }
                // ],
                // patented: [
                //     {
                //         required: true,
                //         message: `请选择是否专利${this.tpType === 'TECHNOLOGY' ? '技术' : '产品'}`,
                //         trigger: 'blur'
                //     }
                // ],
                // patentNo: [{ required: true, message: '请输入包含专利号', trigger: 'blur' }],
                // tradingMethod: [{ required: true, message: '请选择交易方式', trigger: 'blur' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择期望价方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            };
        }
    },
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('technologyProduct/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    this.formData.owner = res.owner.split(',');
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        if (this.$route.query.tpType) {
            this.formData.tpType = this.$route.query.tpType;
        }
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    this.fieldOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },

    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/TechnologyProductEdit',
                                    query: {
                                        ...this.$route.query
                                    }
                                });
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = {
                ...this.formData,
                userId: this.userInfo.id,
                tpType: this.tpType,
                status: 'PENDING',
                contact: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            data.owner = data.owner.join(',');
            // console.log(data);
            this.saving = true;
            this.$http
                .post('/technologyProduct/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/technologyProduct/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
.suffix {
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-top: 30px;
    }
}
</style>
